.<template>
  <div class=container v-if="clb && pjb">
      <ul class=sidebar>
          <b>Research Areas</b>
          <li v-for="category in categories" :key="category.id" > <router-link  class=sidetext :to="{path:'/projects/categories',query:{cid:category.title}}">{{category.title}}</router-link></li>
      </ul>
      <p class=MainTitle>{{project['Title']}} </p>
      <div class=rest>
      <div v-if="project['MainImg']['type']=='video'">
        <video  loop autoplay muted class=MainImg >
          <source class=mi :src="project['MainImg']['dataurl']" type="video/mp4">
        </video>
      </div>
      <div v-else>
        <img class=mi :src="project['MainImg']['dataurl']"/>
      </div>
      <div class=introduction>
          <p class=paragraph v-for="paragraph in project['Intro']" :key="paragraph">{{paragraph}}</p>
      </div>
      <div>
        <span class=Title>Publications</span>
        <br>
        <div class=postTitle>
            <div class=publication v-for="paper in project['publications']" :key="paper">
                        <b >"{{papers[paper].title}}"<br></b>
                        <span v-for="line in papers[paper].rest" :key="line">{{line}}<br> </span>
                        [<a class=url :href="papers[paper].url" target="new">PDF</a>] 
                        [<router-link class=url :to="{path:'/reference',query:{bid:paper}}">bib</router-link>] 
                        [<router-link class=url :to="'/copyright'">&copy;</router-link>] 
            </div>
		</div>
      </div>
      <div v-if="project['Gallery']">
        <p class=Title>Galleries</p>
          <ul class=IconUl>
                <li class=IconLi v-for="gallery in project['Gallery']" :key="gallery.id"> 
                    <a v-if="gallery['special']" :href="gallery.url"><img  class=ti :width='226'   :src="gallery.img" ></a>
                    <router-link v-else class=thumbnail :to="{path:gallery.url,query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}"><img  class=ti :width='226'   :src="gallery.img"  /></router-link>
                    <div class=description>
                        <p class=subtitle>{{gallery.title}}</p>
                        <p v-for="para in gallery.detail" :key="para.id" class=subdetail>{{para}}</p>
                    </div>
                </li>
          </ul>
      </div>
      <div v-if="project['Images']">
        <p class=Title>Images</p>
          <ul class=IconUl>
                <li class=IconLi v-for="image in project['Images']" :key="image.id"> 
                    <router-link v-if="image.special" :to="{path:image.url,query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}"><img  class=ti :width='226'   :src="image.img"  /></router-link>
                    <a v-else class=thumbnail :href="image.url" target="_blank"><img  class=ti :width='226'   :src="image.img"  /></a>
                    <div class=description>
                        <p class=subtitle>{{image.title}}</p>
                        <p v-for="para in image.detail" :key="para.id" class=subdetail>{{para}}</p>
                    </div>
                </li>
          </ul>
      </div>
      <div v-if="project['Videos']" >
        <p class=Title>Videos</p>
        <div >
            <ul class=IconUl>
                <li v-for="video in project['Videos']" :key="video.id" class=IconLi>
                    <a class=thumbnail :href="video.url"><img class=ti :width='226' :src="video.img" /></a>
                    <div class=description> 
                        <p class=subtitle>{{video.title}}</p>
                        <p v-for="para in video.detail" :key="para.id" class=subdetail>{{para}}</p>
                        <a class=url :href="video.url">{{video.urlname}}</a>
                    </div>
                </li>
            </ul>
        </div>
        </div>

      <div v-if="project['Database']" class=Database>
            <p class=Title>Database</p>
            <ul class=IconUl>
                <li v-for="database in project['Database']" :key="database.id" class=IconLi>
                    <router-link class=thumbnail :to="database.url"><img class=ti :width='226' :src="database.img" /></router-link>
                    <div class=description> 
                        <p class=subtitle>{{database.title}}</p>
                        <p v-for="para in database.detail" :key="para.id" class=subdetail>{{para}}</p>
                        <router-link class=url :to="database.url">{{database.urlname}}</router-link>
                    </div>
                </li>
            </ul>
      </div>
      <div v-if="project['Slides']" class=Slides>
        <span class=Title>Slides</span>
        <br>
        <div class=postTitle>
            <ul class=TextOnly>
                <li v-for="list in project['Slides']" :key="list.id" class=TextOnlyElement>
                        <a v-for="slide in list['data']" :key="slide.id" :href="slide.url" class=Url>{{slide.text}}</a> 
                </li>
            </ul>
        </div>
      </div>
      <div v-if="project['SourceCode']" class=SourceCode>
        <span class=Title>Source Code</span>
        <br>
        <div class=postTitle>
            <ul class=TextOnly>
                <li v-for="source in project['SourceCode']" :key="source.id" class=TextOnlyElement>
                        <a :href="source.url" class=SlideUrl>{{source.text}}</a>
                </li>
            </ul>
        </div>
      </div>
        <div v-if="project['Software']" class=RelatedProject>
        <span class=Title>Software</span>
        <br>
        <div  class=postTitle>
                <ul class=TextOnly>
                <li v-for="soft in project['Software']" :key="soft.id" class=TextOnlyElement>
                         <router-link class=SlideUrl :to="{path:soft.url}">{{soft['text']}}</router-link>
                </li>
            </ul>
        </div>
      </div>
      <div v-if="project['RelatedProject']" >
        <span class=Title>Related Project</span>
        <br>
        <div  class=postTitle>
                <ul class=TextOnly>
                <li v-for="project in project['RelatedProject']" :key="project.id" class=TextOnlyElement>
                         <a v-if="project.special" class=SlideUrl :href="project.filename">{{project['text']}}</a>
                         <router-link v-else class=SlideUrl :to="{path:'/projects/categories/project',query:{cid:this.$route.query.cid,pid:project['filename']}}">{{project['text']}}</router-link>
                </li>
            </ul>
        </div>
      </div> 
        <div v-if="project['References']">
        <span class=Title>References</span>
        <br>
        <div  class=postTitle>
                <ul class=TextOnly>
                <li v-for="ref in project['References']" :key="ref.id" class=TextOnlyElement>
                         <span>{{ref}}</span>
                </li>
            </ul>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

    data(){
        return{
            papers:this.papers,
            pjb : this.pjb,
            clb : this.clb,
            ajb:this.ajb,
            categories : this.categories,
            project : this.project
        }
    },
    created(){
        // axios.get("http://localhost:8080/api/Statics/categories/catelogs.json").then((res)=>{this.categories=res.data 
        // this.clb = true})
        // axios.get("http://localhost:8080/api/Statics/projects/"+this.$route.query.pid+".json").then((res)=>{this.project=res.data 
        // this.pjb = true})
        //    axios.get("http://localhost:8080/api/Statics/publications/APA.json").then((res)=>{this.papers=res.data 
        // this.ajb = true})


        axios.get("https://cave.cs.columbia.edu/Statics/categories/catelogs.json").then((res)=>{this.categories=res.data 
        this.clb = true})
        axios.get("https://cave.cs.columbia.edu/Statics/projects/"+this.$route.query.pid+".json").then((res)=>{this.project=res.data 
        this.pjb = true})
        axios.get("https://cave.cs.columbia.edu/Statics/publications/APA.json").then((res)=>{this.papers=res.data 
        this.clb = true})

    }
}
</script>

<style scoped>
/* Body */
 .container{
     /* letter-spacing: .05em; */

     display:inline-block;
     text-align: center;
     text-decoration: none;
     font-size: 18px;
     width:1000px;
     line-height:1.4em;
 }
/* breadcrumb */
.sidebar{
     float: right;
     top:37px;
     margin-top:20px;
     margin-right: -230px;
     text-align: left;
     position:sticky;
     list-style: none;
}
.sidetext{
    text-decoration: none;
    color:black;
      line-height: 1.8em;
}
.sidetext:hover{
     color:rgba(103, 103, 104, 0.842)
}

 /* Main title */
.MainTitle{
        line-height: 35px;
        margin-top: 20px;
        margin-bottom:37px;
        font-weight: bold;
        font-family:'Raleway', sans-serif;
        font-size:37px;
    }

 /* Top image */
 .MainImg{
        max-width:900px;
        min-width:750px;
        min-height:247px;
        border-radius: 3px;
        /* box-shadow:  2px 2px #a5a2a2; */
 }
 .mi{
    border-radius: 3px;
    /* box-shadow:  2px 2px #a5a2a2; */
    max-width: 900px;
    min-width:800px;
    max-height:800px
 }
 /* introduction */
 .introduction{
    padding-top:2vh;
    text-align: left;
    /* font-family: 'Roboto', sans-serif; */
    /* letter-spacing: .05em; */
 }
 .paragraph{
     margin-bottom:0;
 }
 .publication{
    margin-top:8px;
    text-align: left;
    /* font-family: 'Roboto', sans-serif */
 }

 /* list with images */

 .IconUl{
     height:auto;
     list-style: none;
     padding-left:0;
     margin-top:0;

   
 }
 .IconLi{
    display:flex;
    width:1000px;
    padding-left:0;
    margin-left:0;
    padding-top:10px;
    padding-bottom: 8px;
    left:0;
    height:auto;
 }
.thumbnail{
    margin:auto 0;
    float:left;
    padding-left: 0;


 }
 .ti{
    border-radius: 3px;
    border: 2px solid #9c9797;
 }
 .description{
     width:850px;
     /* height:150px; */
     font-size:18px;
     /* font-family: 'Roboto', sans-serif; */
     text-align:left;
     float:left;
     padding-left:30px;
     margin:auto 0;
 }
.subtitle{
        min-width: 180px;
        float:left;
        padding-top:-10px;
        padding-bottom: 0;
        margin: 0;
        font-weight: bold;
    }
.subdetail{
    float:left;
    padding-top:0;
    margin:0;
    width:100%
    /* width:700px; */
}

.Title{
    margin-top: 20px;
    margin-bottom:10px;
    /* padding-top:2vh; */
    width:100%;
    text-align:left;
    font-weight: bold;
    /* font-family:'Roboto', sans-serif; */
    font-size:20px;
    background-color: #ffffff;
    float: left;
}

.TextOnly{
    padding:0;
    list-style:none;
    text-align:left;
    /* padding-top:0;
    padding-left:0;
    display:grid;
    grid-template-columns: 25vh 25vh 25vh ; */
}
.TextOnlyElement{
    padding:0;
    /* width:25vh; */
    /* display:block; */
}
.url{
    color: #000;
    text-decoration: none;
}
.SlideUrl{
    color:black;
    width:100%;
    float:left;
    text-decoration:none;
    /* padding-bottom:18px; */
}
.Url{
    margin-right:30px;
    color:black;
    text-decoration:none;
}
</style>